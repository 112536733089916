import React, { useEffect, useState } from "react"
import { generateBlobs, Blob } from "../../libs/blobs"
// import styles from "../../styles/main.module.css"

const WIDTH = 1200
const HEIGHT = 675

const States  = {
    NO_SELECT: 0,
    DRAG_SELECT: 1,
    BOX_SELECT: 2,
}

export default (props) =>  {
    const [blobs, setBlobs] = useState([])

    // mouse information
    const [mouseStartX, setMouseStartX] = useState(null)
    const [mouseStartY, seTMouseStartY] = useState(null)
    const [mouseDx, setMouseDx] = useState(null)
    const [mouseDy, setMouseDy] = useState(null)

    // what the users are up to
    const [state, setState] = useState(States.NO_SELECT)

    // depending on state we want different coords
    const getX = (index) => {
        const blob = blobs[index]
        const x = Math.max(Math.min(blob.x + (state === States.DRAG_SELECT && blob.selected ? mouseDx : 0), WIDTH), 0)
        // console.log(`getX evaluates to ${x} for index ${index}`)
        return x
    }
    const getY = (index) => {
        const blob = blobs[index]
        const y = Math.max(Math.min(blob.y + (state === States.DRAG_SELECT && blob.selected ? mouseDy : 0), HEIGHT), 0)
        // console.log(`getY evaluates to ${y} for index ${index}`)
        return y
    }

    // select a blob to selected and prepare to drag
    const select = (event, index) => {
        console.log(`selected ${index}`)
        setMouseDx(0)
        setMouseDy(0)
        setMouseStartX(event.clientX)
        seTMouseStartY(event.clientY)

        blobs[index].selected = true

        setState(States.DRAG_SELECT)
    }

    // deselect the blob that was selected
    const deselect = (index) => {
        console.log(`deselected ${index}`)
        setState(States.NO_SELECT)

        blobs.forEach((blob) => {
            if (blob.selected) {
                blob.x += mouseDx
                blob.y += mouseDy
            }
        })

        setMouseStartX(null)
        seTMouseStartY(null)
        setMouseDx(null)
        setMouseDy(null)
    }

    // deselect all when you click on the canvas
    const deselectAll = () => {
        console.log(`deselect ALL`)
        setState(States.NO_SELECT)
        
        blobs.forEach((blob) => {
            blob.selected = false
        })

        setMouseStartX(null)
        seTMouseStartY(null)
        setMouseDx(null)
        setMouseDy(null)
    }

    const move = (event) => {
        if (state === States.DRAG_SELECT && (mouseDx !== null && mouseDy !== null)) {
            setMouseDx(event.clientX - mouseStartX)
            setMouseDy(event.clientY - mouseStartY)
        } else if (state === States.BOX_SELECT) {
            // TODO THIS BITCH LATER
        }
    }

    useEffect(() => {
        setBlobs(generateBlobs())
    /* eslint-disable react-hooks/exhaustive-deps */
    }, [])


    return (
        <div
            onMouseMove={move}
            // TODO add code to avoid deselect all bug and allow us box various items
            onMouseDown={() => null}
            onMouseUp={deselectAll}
            role="none"
            style={{
            position: "relative",
            //left: "0%", // middle
            overflow: "visible",
            width: WIDTH,
            height: HEIGHT,
            border: "1px black",

        }}>
            {blobs.map((blob, index) => {
                return <Blob 
                    role="none"
                    x={getX(index)} 
                    y={getY(index)} 
                    alive={blob.alive} 
                    selected={blob.selected} 
                    key={index} 
                    onMouseDown={(event) => select(event, index)}
                    onMouseUp={() => deselect(index)}
                    />
                }
            )}
        </div>
    )
}